import { useEffect, useState, useMemo } from "react";
import { MDBBtn, MDBContainer } from "mdb-react-ui-kit";

import { useAuth } from "../../components/Auth";
import { CountCard } from "../../components";
import DataTable from "../../components/table/DataTable";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import { FilterIconHeader } from "../../components/FilterIconHeader";
import { SwitchForm } from "../../components/SwitchForm";
import {
  formatScreeningOutcomeHits,
  formatScreeningOutcomeHitsPlainText,
  WatchlistReviewModal
} from "./components";

import { getDataTableColumns } from "./columns";
import {
  convertISOToReadableDate,
  showErrorToast,
  extractUniqueScreeningOutcomeTypes
} from "../../helpers";
import { getWatchlistReports } from "../../helpers/watchlist";
import { exportWatchlistToExcel } from "./export";

import { watchlistSignals } from "../../constants";
import { useLoader } from "stm-frontend-library";

const Watchlist = () => {
  const { components } = useAuth();

  const [error, setError] = useState(false);

  const [allData, setAllData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [totalHits, setTotalHits] = useState(0);
  const [filterSelection, setFilterSelection] = useState([]);
  const [selectedDataRowIndex, setSelectedDataRowIndex] = useState(null);

  const { isLoading, hideLoader, showLoader } = useLoader();

  const handleRowClick = (index) => {
    setSelectedDataRowIndex(index);
  };

  const handleCloseModal = () => setSelectedDataRowIndex(null);

  const dataWithActions = filteredData?.map((element, index) => ({
    ...element,
    timestamp: convertISOToReadableDate(element.timestamp, "DD MMM YYYY"),
    raw_hits: formatScreeningOutcomeHitsPlainText(element.screening_outcomes),
    hits: (
      <span
        dangerouslySetInnerHTML={{
          __html: formatScreeningOutcomeHits(element.screening_outcomes),
        }}
      />
    ),
    manuscript_id: element.manuscript_id ?? "",
    clickEvent: () => handleRowClick(index),
  }));

  useEffect(() => {
    const fetchData = async () => {
      showLoader();
      try {
        const { data: { manuscripts = [], total = 0 } = {} } =
          await getWatchlistReports();
        setAllData(manuscripts);
        setTotalHits(total);
        const uniqueTypes = extractUniqueScreeningOutcomeTypes(manuscripts);
        const initialFilter = Object.fromEntries(
          uniqueTypes.map((type) => [type, true])
        );
        setFilterSelection(initialFilter);
      } catch (error) {
        showErrorToast();
        setError(true);
      } finally {
        hideLoader();
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (Object.keys(filterSelection).length) {
      const filteredManuscripts = allData.filter((manuscript) =>
        manuscript.screening_outcomes.some((outcome) => filterSelection[outcome.type])
      );
      setFilteredData(filteredManuscripts);
    }
  }, [filterSelection]);

  const options = useMemo(() => {
    return watchlistSignals.filter((signal) =>
      components.hasOwnProperty(signal.value)
    );
  }, [components]);

  const handleFilterSubmit = (values) => {
    setFilterSelection(values);
  };

  const handleFilterReset = () => {
    const resetValues = Object.fromEntries(
      Object.keys(filterSelection).map((type) => [type, true])
    );
    setFilterSelection(resetValues);
  };

  const filterComponent = (
    <FilterIconHeader label="Hits" dropdownTitle="Signal Type Selection">
      <SwitchForm
        handleSubmit={handleFilterSubmit}
        handleReset={handleFilterReset}
        initialValues={filterSelection}
        options={options}
      />
    </FilterIconHeader>
  );
  const tableColumns = getDataTableColumns(filterComponent);

  const handleExport = () => {
    exportWatchlistToExcel(dataWithActions, "Watchlist");
  };

  return (
    <MDBContainer className="my-5">
      {selectedDataRowIndex !== null && (
        <WatchlistReviewModal
          contentData={filteredData[selectedDataRowIndex]}
          onClose={handleCloseModal}
        />
      )}
      {error && <ErrorMessage />}
      {!error && !isLoading && (
        <>
          <div className="d-flex justify-content-center m-5">
            <CountCard
              count={totalHits}
              title={"WATCHLIST HITS"}
              subtitle={"Screened manuscripts with hits"}
            />
          </div>
          <div className="d-flex justify-content-end mb-3">
            <MDBBtn
              disabled={isLoading}
              color="primary"
              className="px-4"
              onClick={handleExport}
            >
              Export
            </MDBBtn>
          </div>
          <DataTable columns={tableColumns} rows={dataWithActions} />
        </>
      )}
    </MDBContainer>
  );
};

export default Watchlist;
