import {
    MDBContainer,
} from "mdb-react-ui-kit";
import { mockData } from "./data";
import { convertComponentNames, FullScreeningModal, FullScreeningReportCard, tabs } from "stm-frontend-library";
import { useState } from "react";

const FullScreening = () => {

    const data = mockData?.events;
    const componentFlags = {
        "CRRF": false,
        "OPScr": true,
        "RWtch": true,
        "SSPan": true,
        "SSPdd": true,
        "SSPfd": true,
        "TPHrs": true,
        "AMBScr": true,
        "CSStat": true,
        "SSMeta": true,
        "SSPidd": true,
        "wordFileProperties": true,
        "watchlistChatGPTOutput": true
    }

    const [isArticleModalOpen, setIsArticleModalOpen] = useState(false);
    const [tabId, setTabId] = useState(tabs.externalTools);
    const [datalistIndex, setDatalistIndex] = useState(null);


    const modalContent = data[datalistIndex]?.Attrs
        ? {
            ...data[datalistIndex].Attrs,
            SK: data[datalistIndex]?.SK,
        }
        : "";

    const showInfo = (index, tab) => {
        setTabId(tab);
        setDatalistIndex(index);
        setIsArticleModalOpen(true);
    };


    return (
        <MDBContainer className="my-5">
            <FullScreeningModal
                isOpen={isArticleModalOpen}
                setIsOpen={setIsArticleModalOpen}
                tab={tabId}
                data={modalContent}
                onLoadReport={() => { }}
                onDeleteEvent={() => { }}
                eventDeleting={false}
                // make convertComponentNames one source of truth
                components={convertComponentNames(componentFlags)}
            />
            {data.length > 0 &&
                data.map((item, index) => (
                    <FullScreeningReportCard
                        key={item.SK}
                        item={item}
                        index={index}
                        showInfo={showInfo}
                        components={convertComponentNames(componentFlags)}
                    />
                ))}
        </MDBContainer>
    );
};

export default FullScreening;
