import { useState, useEffect } from "react";
import DataTable from "../../../components/table/DataTable";
import Pagination from "../../../components/Pagination/Pagination";
import ErrorMessage from "../../../components/ErrorMessage/ErrorMessage";
import {
  ReportActionButtons,
  SECTION_KEYS,
} from "../components/ReportActionButtons";
import {
  convertISOToReadableDate,
  getDuplicateSubmissionById,
  showErrorToast
} from "../../../helpers";
import { SignalReportModal } from "../../../components";

import { ApplyAction, ColorizeStatusText } from "../../../components/ApplyAction";

import { useAppContext } from "../../../components/AppContext";
import useAmbientScreenedManuscripts from "../hooks/useAmbientScreenedManuscripts";
import { ambientScreeenedColumns as baseColumns } from "../columns";
import { dropdownOptions } from '../dropdownOptions';
import { useLoader } from "stm-frontend-library";

export const AmbientScreenedManuscriptsTab = ({ isActiveTab, type, showActionColumn }) => {
  const [selectedItem, setSelectedItem] = useState({
    index: null,
    visibleSection: SECTION_KEYS.ALL,
  });
  const [fetchedData, setFetchedData] = useState(null);
  const { searchInput } = useAppContext();

  const {
    data,
    fetchData,
    setData,
    setSubmissionStatus,
    error,
    pageCount,
    currentPage,
    setCurrentPage,
    searchTerm,
    setSearchTerm,
  } = useAmbientScreenedManuscripts({ isActiveTab, type });

  const { showLoader, hideLoader, isLoading } = useLoader();

  const handleIconClick = (index, buttonKey) => {
    setSelectedItem({
      index,
      visibleSection: buttonKey,
    });
  };

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const updateStatus = async (index, status) => {
    const newData = [...data];

    if (type === "wo_status") {
      const previousElement = { ...newData[index] };
      newData.splice(index, 1);
      setData(newData);

      const { id: recordId } = previousElement;
      try {
        await setSubmissionStatus(recordId, status);
        await fetchData();
      } catch {
        showErrorToast();
        newData.splice(index, 0, previousElement);
        setData(newData);
      }
    } else if (type === "w_signals") {
      const previousStatus = newData[index].submission_status;
      newData[index].submission_status = status;

      const { id: recordId } = newData[index];
      try {
        await setSubmissionStatus(recordId, status);
      } catch {
        showErrorToast();
        newData[index].submission_status = previousStatus;
      } finally {
        setData(newData);
      }
    }
  };

  const onActionSelect = (index, status) => {
    updateStatus(index, status);
  };

  const onActionUndo = (index) => {
    updateStatus(index, null);
  };

  const fetchDupSubDataIfNeeded = async (index) => {
    const { persistent_id, signals } = data[index];
    if (persistent_id && signals?.some((s) => s === 'duplicateSubmissions')) {
      try {
        showLoader();
        const { data: fetchedData } = await getDuplicateSubmissionById(persistent_id);
        setFetchedData(fetchedData);
      } finally {
        hideLoader();
      }
    }
  };

  useEffect(() => {
    if (selectedItem.index !== null) {
      fetchDupSubDataIfNeeded(selectedItem.index);
    } else {
      setFetchedData(null);
    }
  }, [selectedItem, data]);

  const resetModalState = () => {
    setFetchedData(null);
    setSelectedItem({
      index: null,
      visibleSection: SECTION_KEYS.ALL,
    });
  };

  useEffect(() => {
    const trimmedValue = searchInput.trim();
    if ((!searchTerm && !trimmedValue) || (trimmedValue === searchTerm)) return;
    setSearchTerm(trimmedValue);
  }, [searchInput, searchTerm, setSearchTerm]);

  const tableColumns = showActionColumn
    ? baseColumns.filter(column => column.field !== 'submission_status')
    : baseColumns.filter(column => column.field !== 'action');

  const dataWithActions = data?.map((manuscript, index) => {
    const row = {
      ...manuscript,
      timestamp: convertISOToReadableDate(manuscript.timestamp, "DD MMM YYYY"),
      signals: (
        <ReportActionButtons
          signals={manuscript.signals}
          onIconClick={(buttonKey) => handleIconClick(index, buttonKey)}
        />
      ),
      submission_status: (
        <ColorizeStatusText statusText={manuscript.submission_status} />
      ),
    };

    if (showActionColumn) {
      row.action = (
        <div className="d-flex">
          <ApplyAction
            index={index}
            status={manuscript.submission_status}
            selectAction={onActionSelect}
            undoAction={onActionUndo}
            options={dropdownOptions}
          />
        </div>
      );
    }

    return row;
  });

  return (
    <>
      {selectedItem.index !== null && (
        <SignalReportModal
          dupSubData={fetchedData}
          contentData={data[selectedItem.index]}
          visibleSection={selectedItem.visibleSection}
          onClose={resetModalState}
        />
      )}
      {error && <ErrorMessage />}
      {!error && data?.length > 0 && (
        <>
          <DataTable columns={tableColumns} rows={dataWithActions} />
          <Pagination
            handlePageClick={handlePageClick}
            pageCount={pageCount}
            currentPage={currentPage}
          />
        </>
      )}
    </>
  );
};

export default AmbientScreenedManuscriptsTab;
