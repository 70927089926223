import { watchlistSignals } from "../../../constants";
import { getScreeningOutcomeItems } from "../../../helpers"

export const getWatchlistData = (contentData) => {
  return {
    watchlistFakeEmailDomains: getScreeningOutcomeItems(
      contentData,
      "watchlistFakeEmailDomains"
    ),
    watchlistFakeAffiliationNames: getScreeningOutcomeItems(
      contentData,
      "watchlistFakeAffiliationNames"
    ),
    watchlistFakeNamesOrEmailAddresses: getScreeningOutcomeItems(
      contentData,
      "watchlistFakeNamesOrEmailAddresses"
    ),
    watchlistDisposableEmailDomains: getScreeningOutcomeItems(
      contentData,
      "watchlistDisposableEmailDomains"
    ),
    watchlistBadActors: getScreeningOutcomeItems(contentData, "watchlistBadActors"),
    watchlistSuspectArticles: getScreeningOutcomeItems(
      contentData,
      "watchlistSuspectArticles"
    ),
    watchlistMetadataSuspects: getScreeningOutcomeItems(
      contentData,
      "watchlistMetadataSuspects"
    ),
    watchlistManuscriptsOfferedForSale: getScreeningOutcomeItems(
      contentData,
      "watchlistManuscriptsOfferedForSale"
    ),
  };
};

export const formatScreeningOutcomeHits = (screeningOutcomes) => {
  if (!screeningOutcomes) return null;
  const labelMap = watchlistSignals.reduce((acc, item) => {
    acc[item.value] = item.label;
    return acc;
  }, {});

  const formattedStatus = screeningOutcomes
    .map(({ type, items }) => {
      const label = labelMap[type];
      return label ? `${label}&nbsp;(x${items.length})` : null;
    })
    .join("<br>");

  return formattedStatus;
};

export const formatScreeningOutcomeHitsPlainText = (screeningOutcomes) => {
  if (!screeningOutcomes) return "";
  const labelMap = watchlistSignals.reduce((acc, item) => {
    acc[item.value] = item.label;
    return acc;
  }, {});

  const formattedStatus = screeningOutcomes
    .map(({ type, items }) => {
      const label = labelMap[type];
      return label ? `${label} (x${items.length})` : null;
    })
    .filter(Boolean)
    .join("\n");

  return formattedStatus;
};
