import { MDBIcon, MDBTooltip } from "mdb-react-ui-kit";
import "./InfoTooltip.css";

export const InfoTooltip = ({ tooltipText }) => {
  return (
    <MDBTooltip className="custom-tooltip"
      tag="a" placement="bottom" title={tooltipText}>
      <MDBIcon fas icon="info-circle" className="text-info ms-2" />
    </MDBTooltip>
  );
};
