import { useEffect, useState } from "react";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import DataTable from "../../components/table/DataTable";
import { columns } from "./columns";
import { convertISOToReadableDate, getClearSkiesList } from "../../helpers";
import { MDBBtn, MDBContainer, MDBIcon } from "mdb-react-ui-kit";
import { CountCard } from "../../components";
import { ClearskiesReviewModal } from "./components/ClearskiesReviewModal";
import { exportClearskiesToExcel } from "./export";
import { useLoader } from "stm-frontend-library";

const Clearskies = () => {
  const [data, setData] = useState([]);
  const [totalHits, setTotalHits] = useState(0);
  const [error, setError] = useState(false);

  const [selectedDataRowIndex, setSelectedDataRowIndex] = useState(null);
  const { isLoading, hideLoader, showLoader } = useLoader();

  const handleRowClick = (index) => {
    setSelectedDataRowIndex(index);
  };

  const handleCloseModal = () => setSelectedDataRowIndex(null);

  const dataWithActions = data?.map((element, index) => ({
    ...element,
    timestamp: convertISOToReadableDate(element.timestamp, "DD MMM YYYY"),
    status: (
      <MDBIcon
        fas
        icon="exclamation-circle"
        size="xl"
        style={{ color: element?.screening_outcomes?.[0].items[0]?.value }}
      />
    ),
    clickEvent: () => handleRowClick(index),
  }));

  useEffect(() => {
    const fetchData = async () => {
      try {
        showLoader();
        const { data: { manuscripts = [], total = 0 } = {} } =
          await getClearSkiesList();
        setData(manuscripts);
        setTotalHits(total);
      } catch (error) {
        setError(true);
      } finally {
        hideLoader();
      }
    };

    fetchData();
  }, []);

  const handleExport = () => {
    exportClearskiesToExcel(dataWithActions, "Clearskies");
  };

  return (
    <MDBContainer className="my-5">
      {selectedDataRowIndex !== null && (
        <ClearskiesReviewModal
          contentData={data[selectedDataRowIndex]}
          onClose={handleCloseModal}
        />
      )}
      {error && <ErrorMessage />}
      {!error && data?.length > 0 && (
        <>
          <div className="d-flex justify-content-center m-5">
            <CountCard
              count={totalHits}
              title={"PAPERMILL SIMILARITY"}
              subtitle={"Screened manuscripts with alarm"}
            />
          </div>
          <div className="d-flex justify-content-end mb-3">
            <MDBBtn
              disabled={isLoading}
              color="primary"
              className="px-4"
              onClick={handleExport}
            >
              Export
            </MDBBtn>
          </div>
          <DataTable columns={columns} rows={dataWithActions} />
        </>
      )}
    </MDBContainer>
  );
};

export default Clearskies;
