import { ModalWrapper } from "../../../components/ModalWrapper";
import { SignalReportSubmissionCard } from "../../../components";
import ClearskiesDataReview from "./ClearskiesDataReview";

export const ClearskiesReviewModal = ({ contentData, onClose }) => {
  const isOpen = !!contentData;

  const handleCloseModal = () => {
    onClose();
  };

  return (
    <ModalWrapper
      title={"ClearSkies Papermill Alarm: Public report"}
      isOpen={isOpen}
      onClose={handleCloseModal}
    >
      <SignalReportSubmissionCard data={contentData} />
      <ClearskiesDataReview data={contentData} withSubHeader />
    </ModalWrapper>
  );
};
