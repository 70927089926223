import { useState, useEffect, useCallback } from "react";
import { ITEMS_PER_PAGE } from "../../../constants";
import {
  showErrorToast,
  getDuplicateSubmissionsReportsByType,
  updateTriggerOrMatchStatus
} from "../../../helpers";
import { useLoader } from "stm-frontend-library";

const useDuplicateSubmissions = ({ type, isActiveTab }) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const [pageCount, setPageCount] = useState(0);

  const { showLoader, hideLoader } = useLoader();

  const [fetchParams, setFetchParams] = useState({
    currentPage: 0,
    type,
  });

  const fetchData = useCallback(async () => {

    try {
      showLoader();
      setError(false);
      const { currentPage, type } = fetchParams;
      const { data: { reports = [], total = 0 } = {} } =
        await getDuplicateSubmissionsReportsByType(
          currentPage * ITEMS_PER_PAGE + 1,
          ITEMS_PER_PAGE,
          type
        );
      setData(reports);
      setPageCount(Math.ceil(total / ITEMS_PER_PAGE));
    } catch {
      showErrorToast();
      setError(true);
    } finally {
      hideLoader();
    }
  }, [fetchParams]);

  useEffect(() => {
    if (isActiveTab) {
      fetchData();
    }
  }, [fetchData, isActiveTab]);

  const updateFetchParams = (newParams) => {
    setFetchParams(prev => ({
      ...prev,
      ...newParams,
      currentPage: newParams.currentPage !== undefined ? newParams.currentPage : prev.currentPage
    }));
  };

  const setTriggerOrMatchStatus = async (matchId, persistentId, status) => {
    try {
      showLoader();
      await updateTriggerOrMatchStatus(matchId, persistentId, status);
    } catch (err) {
      showErrorToast('Error updating status');
      throw error;
    } finally {
      hideLoader();
    }
  };

  return {
    data,
    setData,
    error,
    pageCount,
    currentPage: fetchParams.currentPage,
    setCurrentPage: (page) => updateFetchParams({ currentPage: page }),
    fetchData,
    setTriggerOrMatchStatus
  };
};

export default useDuplicateSubmissions;
