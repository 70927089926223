import React from "react";
import Layout from "./Layout";
import Footer from "./components/Footer";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { LoaderProvider, LoadingOverlay } from "stm-frontend-library";

const App = () => {
  return (
    <LoaderProvider>
      <LoadingOverlay />
      <ToastContainer />
      <Layout />
      <Footer />
    </LoaderProvider>
  );
};


export default App;