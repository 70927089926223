import Jsontableify from "jsontableify";
import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCardTitle,
} from "mdb-react-ui-kit";
import React from "react";
import {
  capitalizeFirstLetter,
  convertISOToReadableDate,
} from "../../../helpers";
import Parser from "html-react-parser";
import { ReportActionButtons } from "../../screened-manuscripts/components/ReportActionButtons";
import "./DuplicateSubmissionsDataReview.css";
import { InfoTooltip } from "../../../components";

const DuplicateSubmissionsDataReview = ({ data, withSubHeader }) => {
  const getStatusSummary = (item) => {
    const results = [];
    const { submission_id, submission_status } = item;

    if (submission_status === "duplicate confirmed") {
      results.push("Duplicate Confirmed");
    } else if (submission_status === "suspect paper mill") {
      results.push("Suspect Paper Mill Reported");
    } else if (submission_status === "false positive") {
      results.push("Reported False Positive");
    }
    if (!submission_id) {
      results.push("Submission Removed");
    }

    return capitalizeFirstLetter(results.join("; "));
  };

  const formatReport = (data) => {
    const formatDate = (date, format = "DD MMM YYYY HH:mm") =>
      date ? convertISOToReadableDate(date, format) : "";

    const isDifferentDate = (date1, date2) => {
      if (!date1 || !date2) return false;
      const d1 = new Date(date1);
      const d2 = new Date(date2);
      return (
        d1.getFullYear() !== d2.getFullYear() ||
        d1.getMonth() !== d2.getMonth() ||
        d1.getDate() !== d2.getDate()
      );
    };

    const formatSubmissionDate = (submittedDate, originalDate) => {
      const formattedSubmitted = formatDate(submittedDate);
      const formattedOriginal =
        originalDate && isDifferentDate(submittedDate, originalDate)
          ? `(original: ${formatDate(originalDate, "DD MMM YYYY")}) `
          : "";

      return `
        <span>
          ${formattedSubmitted} ${
        formattedOriginal
          ? `<span class="highlighted-status">${formattedOriginal}</span>`
          : ""
      }
          ${
            formattedOriginal ? '<span class="tooltip-placeholder"></span>' : ""
          }
        </span>
      `;
    };

    const formatPublisher = (email, manuscriptId, name) => {
      return email
        ? `<a href="mailto:${email}?subject=${manuscriptId}">${name}</a>`
        : name;
    };

    const formatManuscript = (
      {
        submitted,
        manuscript_id: manuscriptId,
        journal,
        publisher_name: publisherName,
        title,
        feedback_email: feedbackEmail,
        original_submission_date: originalSubmissionDate,
        similarity,
        persistent_id,
        ...rest
      },
      submissionPersistentId
    ) => {
      const {
        title: titleSimilarity,
        authors: authorsSimilarity,
        abstract: abstractSimilarity,
      } = similarity ?? {};

      const isOtherPublisher = submissionPersistentId !== persistent_id;

      const formattedTitle =
        title + (titleSimilarity ? ` (Similarity: ${titleSimilarity}%)` : "");

      const submissionStatus = getStatusSummary(rest);

      return {
        ...(submissionStatus && {
          Status: `<span class="highlighted-status">${submissionStatus}</span>`,
        }),
        Date: formatSubmissionDate(submitted, originalSubmissionDate),
        Publisher: isOtherPublisher
          ? `<span class="blured-line">${publisherName}</span>`
          : formatPublisher(feedbackEmail, manuscriptId, publisherName),
        Journal: isOtherPublisher
          ? `<span class="blured-line">${journal}</span>`
          : journal,
        ManuscriptID: isOtherPublisher
          ? `<span class="blured-line">${manuscriptId}</span>`
          : manuscriptId,
        Title: formattedTitle,
        ...(abstractSimilarity && {
          Abstract: `Similarity: ${abstractSimilarity}%`,
        }),
        ...(authorsSimilarity && {
          "Author list": `Similarity: ${authorsSimilarity}%`,
        }),
      };
    };

    const { matches, trigger, persistent_id: submissionPersistentId } = data;

    const reportData = formatManuscript(trigger, submissionPersistentId);
    const formattedMatches =
      matches?.map((match) =>
        formatManuscript(match, submissionPersistentId)
      ) || null;

    return {
      ...reportData,
      "Matched Manuscripts": formattedMatches,
    };
  };

  const renderModalContent = (data) => {
    if (!data) return null;

    const reportData = formatReport(data);
    const jt = new Jsontableify();
    const content = jt.toHtml(reportData).html;

    const parsedContent = Parser(content, {
      replace: (domNode) => {
        if (
          domNode.attribs &&
          domNode.attribs.class === "tooltip-placeholder"
        ) {
          return (
            <InfoTooltip
              tooltipText={
                <>
                  The original submission date was different from the date this
                  submission was received by the STM Integrity Hub. This can be
                  for example due to a revision, or a re-submission after an
                  unsubmit.
                </>
              }
            />
          );
        }
      },
    });

    return <div className="modal_table_wrapper">{parsedContent}</div>;
  };

  return (
    <>
      {(!!data?.matches || !!data?.trigger) && (
        <MDBCard className="my-2">
          {withSubHeader && (
            <MDBCardHeader className="d-flex">
              <ReportActionButtons
                showTooltip={false}
                signals={["duplicateSubmissions"]}
              />
              <MDBCardTitle className="pt-2 ps-2">
                Duplicate submissions
              </MDBCardTitle>
            </MDBCardHeader>
          )}
          <MDBCardBody>{renderModalContent(data)}</MDBCardBody>
        </MDBCard>
      )}
    </>
  );
};

export default DuplicateSubmissionsDataReview;
