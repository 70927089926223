import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCardTitle,
} from "mdb-react-ui-kit";
import {
  renderListItems,
  renderWordFilePropertyItem,
} from "./MetadataAnalysisDataUtils";
import { ReportActionButtons } from "../../screened-manuscripts/components/ReportActionButtons";
import { InfoTooltip } from "../../../components";

const MetadataAnalysisDataReview = ({ data, withSubHeader = false }) => {
  const wordFileProperties =
    data?.screening_outcomes?.find((x) => x.type === "wordFileProperties")
      ?.items ?? [];

  return (
    <>
      {wordFileProperties.length > 0 && (
        <MDBCard>
          {withSubHeader && (
            <MDBCardHeader className="d-flex">
              <ReportActionButtons
                signals={["wordFileProperties"]}
                showTooltip={false}
              />
              <MDBCardTitle className="pt-2 ps-2">
                Word file metadata
                <InfoTooltip
                  tooltipText={
                    <>
                      This signals suspicious patterns in the WORD file metadata,
                      or between the metadata and the manuscript content itself.
                      These patterns were identified by participating publishers.
                    </>
                  }
                />
              </MDBCardTitle>
            </MDBCardHeader>
          )}
          <MDBCardBody>
            {renderListItems(wordFileProperties, renderWordFilePropertyItem)}
          </MDBCardBody>
        </MDBCard>
      )}
    </>
  );
};

export default MetadataAnalysisDataReview;
