import { createContext, useCallback, useContext, useEffect } from "react";
import useState from "react-usestateref";
import { Navigate } from "react-router-dom";
import { token, wstoken } from "../constants";
import { getWsToken, getUser, convertComponentNames } from "../helpers";
import { Spinner } from "stm-frontend-library";

const AuthContext = createContext({});
export const useAuth = () => useContext(AuthContext);
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setAuthenticated] = useState(
    !!localStorage.getItem(token)
  );
  const [, setAvailableTools, availableToolsRef] = useState({});
  const [components, setComponents] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchTools = useCallback(async () => {
    try {
      if (localStorage.getItem(token)) {
        const res = await getUser();
        let { tools, components } = res?.data || {};
        setAvailableTools(tools);
        setComponents(convertComponentNames(components));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [setAvailableTools]);

  useEffect(() => {
    fetchTools();
  }, [fetchTools]);

  const login = async (auth) => {
    localStorage.setItem(token, auth.data.token);
    localStorage.setItem("username", auth.data.username);

    await fetchTools();
    let res = await getWsToken();
    localStorage.setItem(wstoken, res.data);
    setAuthenticated(true);
    return availableToolsRef?.current;
  };
  const logout = () => {
    localStorage.removeItem(token);
    localStorage.removeItem(wstoken);
    setAuthenticated(false);
  };

  const isFeatureEnabled = (feature) => {
    const featureMap = {
      pubpeer: "pubpeer",
      clearskies: "clearskies",
      referenceAnalysis: "referenceAnalysis",
      textAnalysis: "textAnalysis",
      metadataAnalysis: "metadataAnalysis",
      dupsub: "dupsub",
      watchlist: "watchlist",
    };

    return availableToolsRef.current?.[featureMap[feature]] || false;
  };

  useEffect(() => {
    window.addEventListener("force-logout", logout);
    return () => window.removeEventListener("force-logout", logout);
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        isAuthenticated,
        isFeatureEnabled,
        components
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export const RequireAuth = ({ children, redirectTo = "/login", feature }) => {
  const { isAuthenticated, isFeatureEnabled } = useAuth();
  const iAllowed = feature !== undefined ? isFeatureEnabled(feature) : true;
  return isAuthenticated && iAllowed ? children : <Navigate to={redirectTo} />;
};
export default AuthProvider;
