import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBIcon
} from 'mdb-react-ui-kit';
import styled from 'styled-components';

const DropdownToggleStyled = styled(MDBDropdownToggle)`
  padding-top: 0.2em;
    &::after {
        display: none !important;
    }
`;

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StatusWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
`;

const UndoIcon = styled(MDBIcon)`
  cursor: pointer;
`;

export const ApplyAction = ({ index, status, selectAction, undoAction, options }) => {
  const handleDropdownItemClick = (e) => {
    e.preventDefault();
    const value = e.currentTarget.getAttribute('data');
    selectAction(index, value);
  };

  const handleUndoIconClick = (e) => {
    e.preventDefault();
    undoAction(index);
  };

  return (
    <ActionWrapper onClick={e => e.stopPropagation()}>
      {!status && (
        <MDBDropdown>
          <DropdownToggleStyled color='secondary' className='btn-floating'>
            <MDBIcon icon='angle-down' />
          </DropdownToggleStyled>
          <MDBDropdownMenu appendToBody responsive='end'>
            {options && options.map(option => (
              <MDBDropdownItem
                link
                key={option.value}
                data={option.value}
                onClick={handleDropdownItemClick}
              >
                {option.label}
              </MDBDropdownItem>
            ))}
          </MDBDropdownMenu>
        </MDBDropdown>
      )}
      {status && (
        <StatusWrapper >
          <span>{status}</span>
          <UndoIcon
            fas
            icon='arrow-rotate-left'
            size='sm'
            color='primary'
            className='ms-2'
            onClick={handleUndoIconClick}
          />
        </StatusWrapper >
      )}
    </ActionWrapper>
  );
};
