export const mockData = {
    "events": [
        {
            "id": 235,
            "SK": "PAPERMILL#2025-01-08T16:48:41.859Z",
            "Attrs": {
                "userEmail": "vshalahinov@elitex.systems",
                "documentId": "281032c1-be75-415b-8d65-020b2e6d21db",
                "documentName": "bik_title_5_tortured_phrases.DOCX",
                "documentTitle": "TRIM22 alleviates autophagy of lung cancer by activating SIRT1",
                "clearSkiesStatus": null,
                "feetOfClayRatio": {},
                "gptDetectorScore": 99.5,
                "genAIResponse": [
                    {
                        "pattern": "Regenerate response. NOT ChatGpt AND 2023",
                        "sentence": [
                            "And so may any other who knows this in regard to the self regenerate response."
                        ],
                        "expectedText": "Label of a button in ChatGPT, most likely copy-pasted with ChatGPT's output"
                    },
                    {
                        "pattern": "knowledge cutoff in September 2021 NOT ChatGPT",
                        "sentence": [
                            "When all the knots that fetter here the heart are cut asunder, then a mortal becomes immortal <mark>knowledge cutoff in September 2021</mark>."
                        ],
                        "expectedText": "Standard caveat of ChatGPT, see https://community.openai.com/t/knowledge-cutoff-date-of-september-2021/66215"
                    }
                ],
                "refIdentifiers": {
                    "extracted": 0,
                    "parsed": 0,
                    "unidentified": [],
                    "valid": 0,
                    "invalid": 0
                },
                "retractionWatch": {},
                "signals": [
                    {
                        "hits": 1,
                        "pattern": "malignant growth AND cancer",
                        "sentence": [
                            "Then one becomes <mark>malignant growth</mark> undistracted for Yoga comes and goes perspective of sharing economy Preformation in this research.",
                            "Malignant progression on colon <mark>cancer</mark>."
                        ],
                        "expectedText": "cancer"
                    },
                    {
                        "hits": 1,
                        "pattern": "differential condition AND 4th request",
                        "sentence": [
                            "When all desires that <mark>differential condition</mark> dwell within the <mark>4th request</mark> human heart are cast away, then a mortal becomes immortal and (even) here he attaineth to Brahman under big data technology."
                        ],
                        "expectedText": "differential equation AND 4st order"
                    },
                    {
                        "hits": 1,
                        "pattern": "tartanic corrosive",
                        "sentence": [
                            "Thus <mark>tartanic corrosive</mark> far is the teaching."
                        ],
                        "expectedText": "tartanic acid"
                    },
                    {
                        "hits": 1,
                        "pattern": "vacoule",
                        "sentence": [
                            "This <mark>vacoule</mark>."
                        ],
                        "expectedText": "vacuole"
                    },
                    {
                        "hits": 1,
                        "pattern": "preformation in this research",
                        "sentence": [
                            "Then one becomes malignant growth undistracted for Yoga comes and goes perspective of sharing economy <mark>Preformation in this research</mark>."
                        ],
                        "expectedText": "procedure in this research"
                    }
                ],
                "signalsScore": 5,
                "suspects": {
                    "affiliations": [],
                    "emails": [],
                    "identities": [],
                    "tempmails": [],
                    "metadata": []
                },
                "cactusTool": {
                    "status": false,
                    "message": "Unexpected error while uploading the document : [PUBLISHER_NOT_FOUND] The specified publisher was not found. Please check the spelling and try again.",
                    "timestamp": "2025-01-08T16:48:38.282Z",
                    "documentId": ""
                }
            }
        },
        {
            "id": 231,
            "SK": "PAPERMILL#2025-01-07T14:41:09.096Z",
            "Attrs": {
                "userEmail": "vshalahinov@elitex.systems",
                "documentId": "9ffe105f-307f-41b8-be5e-3ff456dab0e6",
                "documentName": "bik_title_5_tortured_phrases.DOCX",
                "documentTitle": "TRIM22 alleviates autophagy of lung cancer by activating SIRT1",
                "clearSkiesStatus": null,
                "feetOfClayRatio": {},
                "gptDetectorScore": 90.12,
                "genAIResponse": [],
                "refIdentifiers": {
                    "extracted": 0,
                    "parsed": 0,
                    "unidentified": [],
                    "valid": 0,
                    "invalid": 0
                },
                "retractionWatch": {},
                "signals": [
                    {
                        "hits": 1,
                        "pattern": "malignant growth AND cancer",
                        "sentence": [
                            "Then one becomes <mark>malignant growth</mark> undistracted for Yoga comes and goes perspective of sharing economy Preformation in this research.",
                            "Malignant progression on colon <mark>cancer</mark>."
                        ],
                        "expectedText": "cancer"
                    },
                    {
                        "hits": 1,
                        "pattern": "differential condition AND 4th request",
                        "sentence": [
                            "When all desires that <mark>differential condition</mark> dwell within the <mark>4th request</mark> human heart are cast away, then a mortal becomes immortal and (even) here he attaineth to Brahman under big data technology."
                        ],
                        "expectedText": "differential equation AND 4st order"
                    },
                    {
                        "hits": 1,
                        "pattern": "tartanic corrosive",
                        "sentence": [
                            "Thus <mark>tartanic corrosive</mark> far is the teaching."
                        ],
                        "expectedText": "tartanic acid"
                    },
                    {
                        "hits": 1,
                        "pattern": "vacoule",
                        "sentence": [
                            "This <mark>vacoule</mark>."
                        ],
                        "expectedText": "vacuole"
                    },
                    {
                        "hits": 1,
                        "pattern": "preformation in this research",
                        "sentence": [
                            "Then one becomes malignant growth undistracted for Yoga comes and goes perspective of sharing economy <mark>Preformation in this research</mark>."
                        ],
                        "expectedText": "procedure in this research"
                    }
                ],
                "signalsScore": 5,
                "suspects": {
                    "affiliations": [],
                    "emails": [],
                    "identities": [],
                    "tempmails": [],
                    "metadata": []
                },
                "cactusTool": {
                    "status": false,
                    "message": "Unexpected error while uploading the document : [PUBLISHER_NOT_FOUND] The specified publisher was not found. Please check the spelling and try again.",
                    "timestamp": "2025-01-07T14:41:05.511Z",
                    "documentId": ""
                }
            }
        },
        {
            "id": 230,
            "SK": "PAPERMILL#2025-01-02T15:23:35.924Z",
            "Attrs": {
                "userEmail": "vshalahinov@elitex.systems",
                "documentId": "cd6b0bb2-263b-4183-8fe2-93c70bb1be9c",
                "documentName": "Test document.docx",
                "documentTitle": "",
                "clearSkiesStatus": null,
                "feetOfClayRatio": {},
                "gptDetectorScore": 40.29,
                "genAIResponse": [
                    {
                        "pattern": "sorry, i cannot generate inappropriate content",
                        "expectedText": "Test1"
                    },
                    {
                        "pattern": "as an ai language model, i",
                        "expectedText": "Test2"
                    }
                ],
                "refIdentifiers": {
                    "extracted": 0,
                    "parsed": 0,
                    "unidentified": [],
                    "valid": 0,
                    "invalid": 0
                },
                "retractionWatch": {},
                "signals": [],
                "signalsScore": 0,
                "suspects": {
                    "affiliations": [],
                    "emails": [
                        {
                            "id": 31,
                            "value": "forecastertests.com",
                            "timestamp": "2024-04-24 08:41",
                            "providedby": "admin@hub.stm-solutions.org"
                        }
                    ],
                    "identities": [],
                    "tempmails": [],
                    "metadata": []
                },
                "cactusTool": {
                    "authorship": [
                        {
                            "extKey": "author_affiliation_relevance",
                            "result": "PASSED",
                            "checkLabel": "Author Affiliation Check",
                            "description": "No mismatch between author affiliations and article field of study"
                        },
                        {
                            "extKey": "author_location",
                            "result": "PASSED",
                            "checkLabel": "Geographic Distribution of Author Affiliations",
                            "description": "No issues found in the geographic distribution of author affiliations"
                        }
                    ],
                    "references": [
                        {
                            "extKey": "age_of_reference",
                            "result": "PASSED",
                            "checkLabel": "Reference Age Distribution",
                            "description": "Reference age distribution appears to be normal"
                        },
                        {
                            "extKey": "reference_cited",
                            "result": "PASSED",
                            "checkLabel": "Reference Citations",
                            "description": "All references have citations"
                        },
                        {
                            "extKey": "citation_cartel_reference",
                            "result": "CRITICAL",
                            "checkLabel": "Excessive Citation To A Particular Author/Group",
                            "description": "Several cited articles belong to the same author or lab group",
                            "additionalDescription": {
                                "summary": "33% of references are detected to be from the same author/lab group (potential citation cartel): ",
                                "additionalData": {
                                    "L Piancastelli": [
                                        "Frizziero L., Piancastelli L., Bombardi T., (2015). GRAPHICALLY ENHANCED VISUAL CONCEALMENT OF LARGE OBJECTS. Journal of Engineering and Applied Sciences, 10(12), 5157-5163."
                                    ],
                                    "L Piancastelli, S Cassani": [
                                        "Piancastelli L., Cassani S., Calzini S., F., Pezzuti E. (2018) Mobility improvement of heavy tracked vehicles: The \"pan\" tank experience. Journal of Engineering and Applied Sciencies, 13(22), 8937-8944.",
                                        "Piancastelli L., Pezzuti E., Cassani S. (2020) Next generation main battle tank. Part I: Upgrading the \"pan\" tanks. Journal of Engineering and Applied Sciencies, 15(11), 1297-1305.",
                                        "Piancastelli L., Pezzuti E., Cassani S. (2020). Next generation main battle tank. Part II: Converting old MBTS into unmanned MBTS (UMBT). Journal of Engineering and Applied Sciences, 15(14), 1582-1591.",
                                        "Piancastelli L., Cassani S., (2020) Next generation main battle tank. Part III: AN AIR TRANSPORTABLE, UPGRADABLE AND FLEXIBLEWEAPON SYSTEM INTEGRATED IN THE FUTURE WARFARE. Journal of Engineering and Applied Sciences, 15(15), 1687- 1694."
                                    ]
                                }
                            }
                        },
                        {
                            "extKey": "self_citation_reference",
                            "result": "PASSED",
                            "checkLabel": "Excessive Self Citation",
                            "description": "No excessive self citation"
                        },
                        {
                            "extKey": "unrelated_reference",
                            "result": "PASSED",
                            "checkLabel": "Citation To Unrelated Research",
                            "description": "No excessive citation to unrelated research"
                        }
                    ],
                    "summaryResult": "CRITICAL"
                }
            }
        }
    ],
    "total": "3"
}