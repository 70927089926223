import {
  renderCardSection,
  renderIndicationDisplay,
  renderAnyHitDetails
} from "./TextAnalysisDataReviewUtils";
import { getScreeningOutcomeItems } from "../../../helpers"

const TORTURED_PHRASES_TOOLTIP = <>
  The text of the manuscript is checked against thousands of
  phrases that were identified by publishers and Dr.
  Guillame Cabanac et al. as likely being AI-generated or
  rewritten. The database is refreshed regularly.
</>;

const TextAnalysisDataReview = ({ data }) => {
  const torturedPhrases = getScreeningOutcomeItems(data, "torturedPhrases");
  const genAIResponse = getScreeningOutcomeItems(data, "genAIResponse");

  return (
    <>
      {renderCardSection(
        torturedPhrases,
        "Tortured phrases",
        renderIndicationDisplay,
        "torturedPhrases",
        TORTURED_PHRASES_TOOLTIP,
        "my-2",
      )}
      {renderCardSection(
        genAIResponse,
        "GenAI Response",
        renderAnyHitDetails,
        "genAIResponse",
        <>
          The text of ...
        </>,
      )}
    </>
  );
};

export default TextAnalysisDataReview;
