export const formatScreeningOutcomeHits = (
  screeningOutcomes,
  signals,
  { 
    separator = "<br>",
    formatCount = (count) => `&nbsp;(x${count})` 
  } = {}
) => {
  if (!screeningOutcomes) return null;

  const labelMap = signals.reduce((acc, {value, label}) => ({
    ...acc, 
    [value]: label
  }), {});

  return screeningOutcomes
    .map(({ type, items }) => {
      const label = labelMap[type];
      return label ? `${label}${formatCount(items.length)}` : null;
    })
    .filter(Boolean)
    .join(separator);
};
