import { useState, useEffect, useCallback } from "react";
import { ITEMS_PER_PAGE } from "../../../constants";
import {
  showErrorToast,
  getAllAmbientScreeningSubmissions,
  updateAmbientScreeningSubmissionStatus
} from "../../../helpers";
import { useLoader } from "stm-frontend-library";

const useAmbientScreenedManuscripts = ({ type, isActiveTab }) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const [pageCount, setPageCount] = useState(0);

  const [fetchParams, setFetchParams] = useState({
    currentPage: 0,
    searchTerm: '',
    type,
  });

  const { hideLoader, showLoader } = useLoader();

  const fetchData = useCallback(async () => {
    try {
      showLoader();
      setError(false);

      const { currentPage, searchTerm, type } = fetchParams;
      let reports, total;
      ({ data: { manuscripts: reports = [], total = 0 } = {} } = await getAllAmbientScreeningSubmissions(
        currentPage * ITEMS_PER_PAGE + 1,
        ITEMS_PER_PAGE,
        searchTerm,
        type
      ));
      setData(reports);
      setPageCount(Math.ceil(total / ITEMS_PER_PAGE));
    } catch {
      showErrorToast();
      setError(true);
    } finally {
      hideLoader();
    }
  }, [fetchParams]);

  useEffect(() => {
    if (isActiveTab) {
      fetchData();
    }
  }, [fetchData, isActiveTab]);

  const updateFetchParams = (newParams) => {
    setFetchParams(prev => ({
      ...prev,
      ...newParams,
      currentPage: newParams.currentPage !== undefined ? newParams.currentPage : prev.currentPage
    }));
  };

  const setSubmissionStatus = async (recordId, status) => {
    try {
      showLoader();
      await updateAmbientScreeningSubmissionStatus(recordId, status);
    } catch (err) {
      showErrorToast('Error updating status');
      throw error;
    } finally {
      hideLoader();
    }
  };

  return {
    data,
    setData,
    fetchData,
    setSubmissionStatus,
    error,
    pageCount,
    currentPage: fetchParams.currentPage,
    searchTerm: fetchParams.searchTerm,
    setSearchTerm: (term) => updateFetchParams({ searchTerm: term, currentPage: 0 }),
    setCurrentPage: (page) => updateFetchParams({ currentPage: page }),
  };
};

export default useAmbientScreenedManuscripts;
