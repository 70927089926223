export const dropdownOptions = [
  {
    label: 'Confirm signal',
    value: 'duplicate confirmed'
  },
  {
    label: 'Flag as paper mill suspect',
    value: 'suspect paper mill'
  },
  {
    label: 'False positive & report to STM',
    value: 'false positive'
  },
  {
    label: 'Hide from Inbox',
    value: 'hidden'
  }
];
