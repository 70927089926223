import { MDBBtn, MDBContainer } from "mdb-react-ui-kit";
import DataTable from "../../components/table/DataTable";
import { useEffect, useState } from "react";
import { columns } from "./columns";
import {
  convertISOToReadableDate,
  getMetadataAnalysis,
  showErrorToast,
} from "../../helpers";
import { MetadataAnalysisModal } from "./components/MetadataAnalysisReviewModal";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import { CountCard } from "../../components";
import { exportMetadataAnalysisToExcel } from "./export";
import { useLoader } from "stm-frontend-library";

const MetadataAnalysis = () => {
  const [error, setError] = useState(false);

  const [data, setData] = useState(null);
  const [totalHits, setTotalHits] = useState(0);

  const [selectedDataRowIndex, setSelectedDataRowIndex] = useState(null);

  const { isLoading, hideLoader, showLoader } = useLoader();

  const handleRowClick = (index) => {
    setSelectedDataRowIndex(index);
  };

  const handleCloseModal = () => setSelectedDataRowIndex(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        showLoader();
        setError(false);
        const { data: { manuscripts = [], total = 0 } = {} } =
          await getMetadataAnalysis();
        setData(manuscripts);
        setTotalHits(total);
      } catch (error) {
        showErrorToast();
        setError(true);
      } finally {
        hideLoader();
      }
    };

    fetchData();
  }, []);

  const dataWithActions = data?.map((element, index) => ({
    ...element,
    timestamp: convertISOToReadableDate(element.timestamp, "DD MMM YYYY"),
    clickEvent: () => handleRowClick(index),
  }));

  const handleExport = () => {
    exportMetadataAnalysisToExcel(dataWithActions, "MetadataAnalysis");
  };

  return (
    <MDBContainer className="my-5">
      {selectedDataRowIndex !== null && (
        <MetadataAnalysisModal
          contentData={data[selectedDataRowIndex]}
          onClose={handleCloseModal}
        />
      )}
      {error && <ErrorMessage />}
      {!error && data?.length > 0 && (
        <>
          <div className="d-flex justify-content-center m-5">
            <CountCard
              count={totalHits}
              title={"ANALYSIS SIGNALS"}
              subtitle={"Screened manuscripts with hits"}
            />
          </div>
          <div className="d-flex justify-content-end mb-3">
            <MDBBtn
              disabled={isLoading}
              color="primary"
              className="px-4"
              onClick={handleExport}
            >
              Export
            </MDBBtn>
          </div>
          <DataTable columns={columns} rows={dataWithActions} />
        </>
      )}
    </MDBContainer>
  );
};

export default MetadataAnalysis;
