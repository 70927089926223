import { ModalWrapper } from "../../../components/ModalWrapper";
import { SignalReportSubmissionCard } from "../../../components";
import TextAnalysisDataReview from "./TextAnalysisDataReview";

export const TextAnalysisReviewModal = ({ contentData, onClose }) => {
  const isOpen = !!contentData;

  const handleCloseModal = () => {
    onClose();
  };

  return (
    <ModalWrapper
      title={"Text analysis signal report"}
      isOpen={isOpen}
      onClose={handleCloseModal}
    >
      <SignalReportSubmissionCard data={contentData} />
      <TextAnalysisDataReview data={contentData} />
    </ModalWrapper>
  );
};
