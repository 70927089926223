import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCardTitle,
} from "mdb-react-ui-kit";
import {
  renderListItems,
  renderClearSkiesItem,
} from "./ClearskiesDataReviewUtils";
import { InfoTooltip } from "../../../components";
import { ReportActionButtons } from "../../screened-manuscripts/components/ReportActionButtons";

const ClearskiesDataReview = ({ data, withSubHeader = false }) => {
  const clearSkiesStatus =
    data?.screening_outcomes?.find((x) => x.type === "clearSkiesStatus")
      ?.items ?? [];

  return (
    <>
      {clearSkiesStatus.length > 0 && (
        <MDBCard className="my-2">
          {withSubHeader && (
            <MDBCardHeader className="d-flex">
              <ReportActionButtons
                signals={["clearSkiesStatus"]}
                showTooltip={false}
              />
              <MDBCardTitle className="pt-2 ps-2">
                Papermill similarity{" "}
                <InfoTooltip
                  tooltipText={
                    <>
                      Provided by Clear Skies Ltd. This service creates an alert
                      when a manuscript appears similar to past
                      papermill-products. Alert ratings are 'red' and 'orange'. <br />
                      &#x25cf; 'red' - highly similar past papermill-papers
                      found.
                      <br />
                      &#x25cf; 'orange' - slightly similar papermill-papers
                      found.
                      <br />
                      <br />
                      Please note that the papermill similarity check is
                      optimized for papers in the field of cancer genetics, and
                      customers should not expect Papermill Alarm: Public to
                      find Papermill-Products outside of the general area of
                      cancer research. Broad scope papermill checks covering all
                      areas of scientific research are available from Clear
                      Skies https://clear-skies.co.uk/
                    </>
                  }
                />
              </MDBCardTitle>
            </MDBCardHeader>
          )}
          <MDBCardBody>
            {renderListItems(clearSkiesStatus, renderClearSkiesItem)}
          </MDBCardBody>
        </MDBCard>
      )}
    </>
  );
};

export default ClearskiesDataReview;
